<template>
  <div class="awl-table table-responsive-md">
    <table v-if="heading && rows" class="table" :class="{ 'table-striped': striped }">
      <thead>
        <tr class="table-primary align-top">
          <th v-for="(head, key) of headingsToShow" :key="key" scope="col" :class="['col-key-' + head.key, 'col-nb-' + key]">
            <slot :name="'header-nb-' + key" :columnKey="head.key" :columnIndex="key" :head="head" :label="head.label">
              <slot :name="'header-key-' + head.key" :columnKey="head.key" :columnIndex="key" :head="head" :label="head.label">
                {{ head.label }}
              </slot>
            </slot>
          </th>
          <th v-if="hasActions" class="actions-column"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, key) of tableRows" :key="key">
          <td v-for="(column, i) of row.tableRow" :key="i" :class="['col-key-' + column.key, 'col-nb-' + i]">
            <slot :name="'column-nb-' + i" :columnKey="column.key" :columnIndex="i" :value="column.content" :row="row.object" :rowIndex="key">
              <slot :name="'column-key-' + column.key" :columnKey="column.key" :columnIndex="i" :value="column.content" :row="row.object" :rowIndex="key">
                {{ column.content }}
              </slot>
            </slot>
          </td>
          <td v-if="hasActions" class="actions-column p-0 p-md-3">
            <slot name="actions" :row="row.object">
              <div class="actions-container d-flex align-items-center justify-content-end">
                <slot name="actions-content" :row="row.object"></slot>
              </div>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  props: {
    heading: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    striped: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headingsToShow () {
      return this.heading.filter(h => !h.hidden);
    },
    tableRows () {
      return this.rows.map(row => {
        const tableRow = [];

        for (const head of this.headingsToShow) {
          tableRow.push({
            key: head.key,
            content: row[head.key],
          });
        }

        return {
          tableRow,
          object: row,
        };
      });
    },
    hasActions () {
      return !!this.$scopedSlots.actions || !!this.$scopedSlots['actions-content'];
    },
  },
};

</script>

<style lang="scss">

$table-content-row-color-odd: #F2F2F2 !default;
$table-content-row-color-even: #fff !default;

.awl-table {
  border: 1px solid rgba($body-color, .075);
  border-radius: 5px;
  overflow: hidden;

  thead {
    background: rgba($body-color, .075);

    td, th {
      border: 0;
      text-transform: uppercase;
      color: rgba($body-color, .6);
      font-size: 14px;
    }
  }

  tbody {
    tr {
      td {
        background: $table-content-row-color-even;
        padding: .75rem;
        border-color: rgba($body-color, .075);
      }

      &:first-child {
        td {
          border: 0;
        }
      }
    }
  }

  td {
    vertical-align: middle !important;
  }

  .actions-column {
    @include media-breakpoint-down("sm") {
      box-shadow: -8px 0px 12px -8px rgba(0,0,0,.3);
      position: sticky;
      right: 0;
    }
  }
}

</style>
