<template>
  <div class="d-inline-block">
    <template v-if="to">
      <router-link class="btn btn-secondary btn-sm d-none d-md-block action-btn" :class="btnClasses" :disabled="disabled" :to="to">
        <i v-if="iconClass" :class="iconClass"></i> {{ label }}
      </router-link>
      <router-link class="d-md-none btn btn-link" :class="btnClassesMobile" :disabled="disabled" :to="to">
        <i v-if="iconClass" :class="iconClass"></i>
      </router-link>
    </template>
    <template v-else>
      <button class="btn btn-secondary btn-sm d-none d-md-block action-btn" :class="btnClasses" :disabled="disabled" @click.prevent="$emit(eventName, $event)">
        <i v-if="iconClass" :class="iconClass"></i> {{ label }}
      </button>
      <button class="d-md-none btn btn-link" :class="btnClassesMobile" :disabled="disabled" @click.prevent="$emit(eventName, $event)">
        <i v-if="iconClass" :class="iconClass"></i>
      </button>
    </template>
  </div>
</template>

<script>

import actionMixin from './mixins/action.js';

export default {
  mixins: [actionMixin],
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};

</script>

<style lang="scss">

.action-btn {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: .04rem;
  border: 0;
  font-size: 12px;
}

</style>
